const initialState = {
  data: [],
  transaction: {},
  is_error: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_INVOICE":
      const { payment_methods, transaction: tr } = payload;
      return { ...state, data: payment_methods, transaction: tr };

    default:
      return state;
  }
};

export default reducer;
