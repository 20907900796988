import React from "react";
// import CloseIcon from "../../Assets/close.svg";
// import LineIcon from "../../Assets/line.svg";
// import Switch from "react-switch";
// import { useTranslation } from "react-i18next";

const Header = ({
  title = "Select Payment",
  showCloseBtn = true,
  closeAction = () => {
    alert("oke");
  },
}) => {
  // const [Language, setLanguage] = useState(localStorage.getItem("lang"));
  // const { i18n } = useTranslation();

  return (
    <div className="header sticky-top">
      <div className="row ms-0 p-3 h-100 w-100">
        <div className="col m-auto ps-3">
          <img
            src="https://storage.googleapis.com/kang-go.appspot.com/b2b-client/development/1686121527381.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=Hhl04NCLxR7jPGFuUE4wip%2FBsdcrPiVaYgqCgrhOAYN5d0RZ6VIquMhZvZw7OMmhcw71HHu3yOYkWS6eCOEfI0KLOD7CYV%2Fkxf47zyUcipiv2V%2BhQl7%2FezYkwFz%2B6EHI0H5G40aOh7Q8DUZGVi5JV3ZmGg%2FQ6i3SFu6oD2sXAIIK2MEzsembPhq3LN1jD1R3bKpgTnX0MGaBHDe%2BS8Y94nn4XiLTOM98wNd0sPQkUBt33mwFfJQ6pLoS3lA9UzVZhNiNNU6dnOdIk6pICe%2BjTpKpNvWAAPL5WMaRw%2B3PCXQI%2BFLWLXz49rfonjdKUChALiMI7QnfMZVC0S22%2FeH4Dw%3D%3D"
            alt=""
            srcSet="https://storage.googleapis.com/kang-go.appspot.com/b2b-client/development/1686121527381.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=Hhl04NCLxR7jPGFuUE4wip%2FBsdcrPiVaYgqCgrhOAYN5d0RZ6VIquMhZvZw7OMmhcw71HHu3yOYkWS6eCOEfI0KLOD7CYV%2Fkxf47zyUcipiv2V%2BhQl7%2FezYkwFz%2B6EHI0H5G40aOh7Q8DUZGVi5JV3ZmGg%2FQ6i3SFu6oD2sXAIIK2MEzsembPhq3LN1jD1R3bKpgTnX0MGaBHDe%2BS8Y94nn4XiLTOM98wNd0sPQkUBt33mwFfJQ6pLoS3lA9UzVZhNiNNU6dnOdIk6pICe%2BjTpKpNvWAAPL5WMaRw%2B3PCXQI%2BFLWLXz49rfonjdKUChALiMI7QnfMZVC0S22%2FeH4Dw%3D%3D"
            className="img-fluid"
          />
        </div>
        {/* <div className="col m-auto m-0 p-0 text-end">
          <span className="font-averta-bold" style={{ fontSize: 12 }}>
            {title}
            {title.length > 20 ? title.substring(0, 20) : title}
          </span>
        </div> */}
        {/* <div className="col m-auto m-0 p-0">
          <div className="d-flex justify-content-end align-items-center">
            <Switch
              checked={Language === "id"}
              onChange={() => {
                i18n.changeLanguage(Language === "id" ? "en" : "id");
                setLanguage(Language === "id" ? "en" : "id");
                localStorage.setItem("lang", Language === "id" ? "en" : "id");
              }}
              height={28}
              width={56}
              onHandleColor="#FFFFFF"
              offHandleColor="#A61E23"
              onColor="#A61E23"
              offColor="#E8E8E8"
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  ID
                </div>
              }
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#A61E23",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  EN
                </div>
              }
            />
            <div className={`${!showCloseBtn && "opacity-0"}`}>
              {showCloseBtn && (
                <>
                  <img src={LineIcon} className="mx-2" alt="" srcSet="" />
                  <img
                    src={CloseIcon}
                    className="btn-x"
                    onClick={closeAction}
                    alt="x"
                    srcSet=""
                  />
                </>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
