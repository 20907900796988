import React from "react";
import ReactHtmlParser from "react-html-parser";

const HtmlRender = ({
  html = `<ul><li>term1 </li><li>term2</li><li>term3</li><li>term4</li></ul>`,
}) => {
  return <div>{ReactHtmlParser(html)}</div>;
};

export default HtmlRender;
