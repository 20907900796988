import { paySimulate } from "./Api";

export const pay = async (data) => {
  const { amount, invoice_number, payment_code, external_id, category_id } =
    data;

  let url = null;
  let payload = {};

  if (category_id === 1) {
    payload = {
      bank_account_number: invoice_number,
      bank_code: payment_code,
      external_id,
      transfer_amount: String(amount),
    };
    url = `${process.env.REACT_APP_API_URL}/switching/api/v1/payment/xendit/pay/virtual_account`;
  }
  if (category_id === 6) {
    payload = {
      retail_outlet_name: payment_code,
      payment_code: invoice_number,
      transfer_amount: amount,
    };
    url = `${process.env.REACT_APP_API_URL}/switching/api/v1/payment/xendit/pay/retail`;
  }
  if (category_id === 7) {
    payload = { external_id: external_id };
    url = `${process.env.REACT_APP_API_URL}/switching/api/v1/payment/xendit/pay/qr`;
  }
  
  const redirect = data.succeed_redirect_url || "https://kanggo.id";
  await paySimulate(url, payload, redirect);

  // window.location.href = data.source_redirect_url || 'https://kanggo.id';
};
