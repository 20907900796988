import React from "react";
import ArrowRightIcon from "../../Assets/arrownew.svg";
import ArrowRightWhiteIcon from "../../Assets/arrow-white.svg";

const ArrowRight = ({color = "primary" }) => {
  return (
    <div>
      <img src={color === "primary" ? ArrowRightIcon : ArrowRightWhiteIcon} alt="" srcSet="" />
    </div>
  );
};

export default ArrowRight;
