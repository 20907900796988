import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FooterFixed from "../Components/Molecules/FooterFixed";
import AmountDetail from "../Components/Organisms/AmountDetail";
import Card from "../Components/Organisms/Card";
import Container from "../Components/Organisms/Container";
import Countdown from "../Components/Organisms/Countdown";
import CreditCardXendit from "../Components/Organisms/CreditCardXendit";
import FormPhoneNumber from "../Components/Organisms/FormPhoneNumber";
import Frame from "../Components/Organisms/Frame";
// import Header from "../Components/Organisms/Header";
import ModalComp from "../Components/Organisms/Modal";
import PaymentProcedureSection from "../Components/Organisms/PaymentProcedureSection";
import SkeletonComp from "../Components/Organisms/Skeleton";
import { getInvoiceData } from "../Redux/Actions/invoice";
import { alert } from "../Utils/Alert";
import { instance } from "../Utils/Api";
import { createToken } from "../Utils/CreateToken";
import { getFee } from "../Utils/GetFee";
import { analytics, eventMask, gaLogEvent } from "../Utils/FirebaseConfig";

const PaymentDetail = ({ socket }) => {
  const invoice = useSelector((state) => state.invoice?.transaction);
  const [IsLoading, setIsLoading] = React.useState(false);
  const [PhoneNumber, setPhoneNumber] = React.useState("62 ");
  const [CC, setCC] = React.useState(null);
  const [ShowFrame, setShowFrame] = React.useState(false);
  const [ShowTnC, setShowTnC] = React.useState(false);
  const [SrcFrame, setSrcFrame] = React.useState("https://kanggo.id");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { state, pathname } = location;
  const { title, method } = state;

  const fetch = () => socket.emit("check-transaction", invoice?.external_id);
  return (
    <>
      {ShowFrame && <Frame src={SrcFrame} />}
      <Container>
        {/* <Header title={title} closeAction={() => navigate(-1)} /> */}
        {IsLoading && <SkeletonComp />}
        {!IsLoading && (
          <div className="p-3 mb-5">
            {!invoice?.payment_id && invoice?.is_set_expired && (
              <Countdown date={invoice?.expired_at} callback={fetch} />
            )}
            <ModalComp show={ShowTnC} onHide={() => setShowTnC(!ShowTnC)}>
              <span className="font-averta" style={{ fontSize: 13 }}>
                Pengguna Aplikasi Kanggo berkewajiban untuk membayar secara
                penuh atas setiap transaksi yang telah ditentukan dalam jangka
                waktu tertentu oleh Kanggo untuk setiap metode pembayaran
                sebelum Kanggo bisa memproses lebih lanjut atas transaksi dari
                Pengguna Aplikasi Kanggo. Apabila pelanggan belum melakukan
                pembayaran dalam kurun waktu yang Kanggo tentukan, maka pihak
                Kanggo berhak menyatakan bahwa transaksi telah dibatalkan oleh
                pelanggan.
              </span>
            </ModalComp>
            <Card>
              <AmountDetail
                showAmount={true}
                amount={
                  invoice?.original_amount +
                  getFee({ ...method, amount: invoice?.original_amount })
                }
                textAmount={t("amount")}
                invoice={invoice?.external_id}
                fee={getFee({ ...method, amount: invoice?.original_amount })}
              />
            </Card>

            <div className="gap"></div>
            {["CC"].includes(method.code) && method.provider === "XENDIT" && (
              <Card>
                <CreditCardXendit
                  amount={
                    invoice?.original_amount +
                    getFee({ ...method, amount: invoice?.original_amount })
                  }
                  setData={setCC}
                  setShowFrame={setShowFrame}
                  setSrcFrame={setSrcFrame}
                  setIsLoading={setIsLoading}
                  navigate={navigate}
                  url={method?.url}
                  external_id={invoice?.external_id}
                  payment_gateway_id={method?.id}
                />
              </Card>
            )}

            <div className="gap"></div>
            {["ID_OVO", "KANGGBLUBCA01"].includes(method.code) && (
              <FormPhoneNumber
                value={PhoneNumber}
                setValue={setPhoneNumber}
                method={method}
              />
            )}

            <div className="gap"></div>
            {method.flows.length > 0 && (
              <Card>
                <PaymentProcedureSection method={method} t={t} />
              </Card>
            )}
          </div>
        )}
      </Container>
      {!IsLoading && (
        <FooterFixed>
          <div className="d-flex flex-column align-items-center w-100">
            <button
              className={`btn btn-danger w-100 btn-gradient-primary radius-50 mb-1`}
              disabled={
                ["ID_OVO", "KANGGBLUBCA01"].includes(method.code)
                  ? !new RegExp("^62[0-9]{10,}$").test(PhoneNumber)
                  : ["CC"].includes(method.code)
                  ? CC === null
                  : false
              }
              onClick={() => {
                setIsLoading(true);
                ["CC"].includes(method.code)
                  ? createToken(CC, invoice?.secret_key)
                  : instance
                      .post(method?.url, {
                        external_id: invoice?.external_id,
                        payment_gateway_id: method?.id,
                        ...(["ID_OVO", "KANGGBLUBCA01"].includes(
                          method.code
                        ) && {
                          mobile_number: PhoneNumber,
                        }),
                      })
                      .then(() => {
                        dispatch(getInvoiceData(invoice?.external_id));

                        // send event to google analytic
                        gaLogEvent(
                          analytics,
                          eventMask(process.env.REACT_APP_EVENT_CHECKOUT_CLICK)
                        );

                        setTimeout(() => {
                          navigate(`/checkout`, {
                            state: { title, method },
                          });
                        }, 1000);
                      })
                      .catch(() => {
                        setIsLoading(false);
                        alert(
                          "error",
                          "terjadi kesalahan, silakan coba kembali"
                        );
                      });
              }}
            >
              {t("payNow")}
            </button>
            <span className="tnc text-center">
              <Trans
                i18nKey={"tnc"}
                components={[
                  <span
                    className="link-tnc"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowTnC(true)}
                  />,
                ]}
              />
            </span>
          </div>
        </FooterFixed>
      )}
    </>
  );
};

export default PaymentDetail;
