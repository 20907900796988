export const createToken = (
  { payload, callback },
  credential = process.env.REACT_APP_XENDIT_KEY
) => {
  // e.preventDefault();
  //   console.log(payload)
  // window.Xendit.setPublishableKey(process.env.REACT_APP_XENDIT_KEY);
  window.Xendit.setPublishableKey(atob(credential));

  // window.Xendit._useStagingURL(true)
  // const payload = {
  //   card_number: number.replace(/\s/g, ""),
  //   card_exp_month: expiry.split("/")[0],
  //   card_exp_year: expiry.split("/")[1],
  //   card_cvn: cvc,
  //   amount,
  //   //   amount: 1000,
  //   is_multiple_use: false,
  //   should_authenticate: true,
  // };
  //   console.log(payload);
  window.Xendit.card.createToken(payload, callback);
  return false;
};
