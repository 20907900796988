import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Container from "../Components/Organisms/Container";
import Header from "../Components/Organisms/Header";

const ListPaymentCatSkeleton = () => {
  return (
    <Container>
      <Header title="Loading" closeAction={() => {}} />
      <div className="p-2">
        <SkeletonTheme baseColor="#FFFF" highlightColor="#eaeaea">
          <p>
            <Skeleton height={90} count={6} />
          </p>
        </SkeletonTheme>
      </div>
    </Container>
  );
};

export default ListPaymentCatSkeleton;
