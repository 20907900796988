import React from "react";
const FooterFixed = ({ children }) => {
  return (
    <div className="mt-5">
      <div className="container d-flex p-3 justify-content-center fixed-bottom p-0 col col-lg-4 col-sm-12 d-grid bg-white">
        {children}
      </div>
    </div>
  );
};

export default FooterFixed;
