import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalComp = ({ size = 'md', show, onHide, children }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalComp;
