export const setPaymentMethod = (paymentMethodId) => {
  return (dispatch) => {
    dispatch({ type: "SET_PAYMENT_METHOD", payload: paymentMethodId });
  };
};

export const setIsChangePayment = (isChange) => {
  return (dispatch) => {
    dispatch({ type: "SET_IS_CHANGE_PAYMENT_METHOD", payload: isChange });
  };
};
