import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

const PendingPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const socket = window.socket;

  const [externalId, setExternalId] = useState(null);

  socket.on("payment-pending-cc", (data) => {
    const { external_id, redirect_url } = data;

    // alert(JSON.stringify(data, externalId))
    if (external_id === searchParams.get("q")) {
      window.location.href = redirect_url || "https://kanggo.id";
    }
  });

  socket.on("payment-success", (data) => {
    if (data === searchParams.get("q")) {
      navigate("/success", {state: externalId});
    }
  });

  useEffect(() => {
    const external_id = searchParams.get("q");
    setExternalId(external_id || null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center position-absolute w-100 h-100 text-center">
      <img src={`${window.location.origin}/load.gif`} alt="" />
      <span className="d-block fw-600 fw-bold my-2 font-inter text-payment-status">
        {t("pending")}
      </span>
      <span className="d-block font-inter fw-400">
        {t("pendingDescription")}
      </span>
    </div>
  );
};

export default PendingPage;
