import React from "react";

const Container = ({children, className}) => {
  return (
    <div className={`container container-custom p-0 col col-lg-4 col-sm-12 ${className || ''}`}>
        {children}
    </div>
  );
};

export default Container;
