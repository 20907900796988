import React, { useState, useEffect } from "react";
import { t } from "i18next";
import moment from "moment-timezone";

const Countdown = ({ date, callback, type = "header" }) => {
  const [countdown, setCountdown] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = moment.tz(date, "Asia/Jakarta");

    // Update the countdown every second
    const interval = setInterval(() => {
      const currentTime = moment().tz("America/New_York");
      const duration = moment.duration(targetDate.diff(currentTime));

      if (duration.asSeconds() <= 0) {
        clearInterval(interval);
        return callback();
      }

      setCountdown({
        hours: duration.days() * 24 + duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    }, 1000);

    return () => {
      // Clean up the interval on component unmount
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { hours, minutes, seconds } = countdown;

  if (type === "header") {
    return (
      // <div className="bg-maroon p-2 text-center">
      //   <span className="text-light font-inter fw-400">
      //     {t("alertOrderExpired")}
      //   </span>
      //   <span className="text-light font-inter fw-400 fw-bold">{` ${hours}:${minutes}:${seconds}`}</span>
      // </div>

      <div className="d-flex justify-content-between bg-gradient-countdown align-items-center p-3">
        <div className="d-flex justify-content-between aling-items-center">
          <img
            src={`${window.location.origin}/time.png`}
            alt=""
            style={{ width: 35, height: 37 }}
            className="me-1"
          />
          <div className="d-flex flex-column justify-content-between">
            <span className="font-averta-bold font-size-14">
              Sisa waktu pemesanan
            </span>
            <span className="font-averta font-size-14">
              Pilih metode pembayaran dan bayar!
            </span>
          </div>
        </div>

        <span className="font-averta-black font-size-16 text-maroon pe-2">{` ${hours}:${minutes}:${seconds}`}</span>
      </div>
    );
  } else {
    return (
      <div className="d-flex flex-column">
        <span className="font-averta-bold">{t("expiredReminder")}</span>
        <span style={{ marginTop: -3 }} className="font-averta">
          {date?.split(" ")[0]} |{" "}
          <span className="text-hours">{` ${hours}:${minutes}:${seconds}`}</span>
        </span>
      </div>
    );
  }
};

export default Countdown;
