const Xendit = window.xendit;
const { card } = Xendit;

const clearNumber = (value = "") => {
  return value.replace(/\D+/g, "");
};

export { clearNumber };

export const formatExpirationDate = (value) => {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
  }

  return clearValue;
};
export const formatCvn = (value) => {
  const clearValue = clearNumber(value);
  return clearValue;
};
export const formatNumber = (value) => {
  const clearValue = clearNumber(value);
  if (clearValue.length >= 5) {
    function chunk(str, n) {
      var ret = [];
      var i;
      var len;

      for (i = 0, len = str.length; i < len; i += n) {
        ret.push(str.substr(i, n));
      }

      return ret;
    }

    return chunk(clearValue.slice(0, 16), 4).join(" ");
  }
  return clearValue;
};

export const validateCardNumber = (value) => {
  value = clearNumber(value);
  return card.validateCardNumber(clearNumber(value));
};
export const validateCardCvn = (value) => {
  value = clearNumber(value);
  return card.validateCvn(value.length > 0 ? value : "cvn");
};
export const validateCardExpiry = (value1, value2) =>
  card.validateExpiry(value1, value2);

// export const xenditResponseHandler = (err, creditCardToken) => {
//     console.log("masuk");
//     try {
//       if (err) {
//         console.log(err);
//         alert(JSON.stringify(err));
//       }
//       if (
//         creditCardToken.status === "VERIFIED" ||
//         creditCardToken.status === "APPROVED"
//       ) {
//         // Get the token ID:
//         const token = creditCardToken.id;
//         alert(token);
//         // window.location.href = 'http://localhost:3000'
//         setShowFrame(false);
//       } else if (creditCardToken.status === "IN_REVIEW") {
//         alert(JSON.stringify(creditCardToken));
//         console.log(creditCardToken);
//         // window.location.href = creditCardToken.payer_authentication_url;
//         // window.open(creditCardToken.payer_authentication_url, 'sample-inline-frame');
//         setSrcFrame(creditCardToken.payer_authentication_url);
//         setShowFrame(true);
//       } else if (creditCardToken.status === "FAILED") {
//         alert(JSON.stringify(creditCardToken));
//         console.log(creditCardToken.failure_reason);
//         setDisableBtnCreateToken(false);
//         // Re-enable submission
//       }
//     } catch (error) {
//       console.log(error, "<<<");
//     }
//   };

// export const createToken = (e, DisableBtnCreateToken, setDisableBtnCreateToken, payload) => {
//     e.preventDefault();
//     if (DisableBtnCreateToken) {
//       alert("something wrong!");
//     } else {
//       setDisableBtnCreateToken(true);

//       Xendit.setPublishableKey(
//         "xnd_public_development_iY9AEC6t3ihIUSbHWvg20gL2u6xAkvU0JYmN0vax900SOSwPGV8efIvtDpqreQ"
//       );

//       // Xendit._useStagingURL(true)
//     //   const payload = {
//     //     card_number: number,
//     //     card_exp_month: expiry.split("/")[0],
//     //     card_exp_year: expiry.split("/")[1],
//     //     card_cvn: cvc,
//     //     amount: 100000,
//     //     is_multiple_use: false,
//     //     should_authenticate: true,
//     //   };
//       alert(JSON.stringify(payload));
//       Xendit.card.createToken(payload, xenditResponseHandler);
//       return false;
//     }
//   };
