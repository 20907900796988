import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./Reducers";

export default configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

// export const store = configureStore({
//   // reducer: rootReducer
// });
