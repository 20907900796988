import React from "react";
import { useTranslation } from "react-i18next";

const SuccessPage = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column justify-content-center align-items-center position-absolute w-100 h-100 text-center">
      <img src={`${window.location.origin}/success.png`} alt="" />
      <span className="d-block fw-600 fw-bold my-2 font-inter text-payment-status">
        {t("succeed")}
      </span>
      <span className="d-block font-inter fw-400">
        {t("succeedDescription")}
      </span>
    </div>
  );
};

export default SuccessPage;
