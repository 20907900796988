import React from "react";

const AmountDetail = ({
  amount,
  invoice,
  textAmount = "Amount",
  showAmount = true,
  fee,
}) => {
  return (
    <div>
      {showAmount && (
        <div className="row m-0 py-3 border-bottom border-list">
          <div className="d-flex justify-content-between">
            <span className="font-averta-bold text-kanggo-black font-size-14">
              {textAmount}
            </span>
            <span className="font-averta-bold red-gradient font-size-14">
              Rp{new Intl.NumberFormat("id").format(amount)}
            </span>
          </div>
          {fee !== 0 && <div className="d-flex justify-content-between">
            <span className="font-averta text-kanggo-grey font-size-14">
              Termasuk biaya admin bank
            </span>
            <span className="font-averta-bold text-kanggo-grey font-size-14">
              Rp{new Intl.NumberFormat("id").format(fee)}
            </span>
          </div>}
        </div>
      )}
      <div className="row m-0 py-3">
        <div className="d-flex justify-content-between">
          <span className="font-averta text-kanggo-black font-size-14">
            Invoice
          </span>
          <span className="font-averta-bold text-kanggo-black font-size-14">
            {invoice}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AmountDetail;
