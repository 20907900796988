export const getFee = ({ amount, fee_nominal, fee_percentage, tax_fee }) => {
  if (fee_nominal === 0 && fee_percentage === 0) return 0;
  let fee = Math.ceil((100 / (100 - fee_percentage)) * amount) + fee_nominal;
  fee -= amount
  const taxFee = Math.ceil(fee * tax_fee)
  return fee + taxFee;
  // amount += taxFee
  // fee -= amount
  //   return new Intl.NumberFormat("id").format(
  //     Math.ceil((100 / (100 - fee_percentage)) * amount) + fee_nominal - amount
  //   );
};
