import React from "react";
import ReactInputMask from "react-input-mask";
import { clearNumber } from "../../Utils/CardLogic";
import Card from "./Card";

const FormPhoneNumber = ({ value, setValue, method }) => {
  const PhoneNumberRules = new RegExp("^62[0-9]{10,}$").test(value);
  console.log(method)
  return (
    <Card>
      {/* <ReactInputMask
        mask="62 999 9999 999999"
        maskChar=" "
        value={value}
        onChange={(e) => setValue(clearNumber(e.target.value))}
      >
        {(inputProps) => (
          <input
            {...inputProps}
            type="tel"
            className={`form-control border-bottom ${
              PhoneNumberRules ? "is-valid" : "is-invalid"
            }`}
            value={value}
            placeholder="Phone Number"
          />
        )}
      </ReactInputMask> */}
      <div className="d-flex justify-content-between align-items-center ps-1">
        <span className="font-averta-bold font-size-16">{method?.method}</span>
        <img src={method?.image_url} alt={method?.method} style={{width: 50}} />
      </div>

      <div className="gap"></div>

      <form className="row px-3">
        <label htmlFor="" className="form-label font-averta font-size-14 ps-0">
          Nomor HP kamu yang terdaftar
        </label>
        <ReactInputMask
          mask="62999 9999 999999"
          maskChar=" "
          type="tel"
          name=""
          value={value}
          onChange={(e) => setValue(clearNumber(e.target.value))}
          className={`form-control font-averta font-size-14 ${
            PhoneNumberRules ? "is-valid" : "is-invalid"
          }`}
          id=""
        />
      </form>
      <span className="font-averta font-size-12 text-kanggo-grey ps-2">
        Contoh: 62851 5523 2232
      </span>
    </Card>
  );
};

export default FormPhoneNumber;
