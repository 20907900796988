import React from "react";
import HtmlRender from "./HtmlRender";

const HowToPay = ({ data = '' }) => {
  return (
    <div className="mb-5">
      <div id="" className="collapse show content px-3">
        <HtmlRender html={data} />
        {/* {data.map((e, i) => (
          <div key={i}>
            <div className="row m-0 py-3 border-bottom border-list">
              <div className="col ps-0 mx-0 m-auto text-start">
                <span className="pay-flow fw-400 fs-normal font-inter text-primary-custom">
                  {`${e.id}. ${e.flow}`}
                </span>
              </div>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default HowToPay;
