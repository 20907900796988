import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import SkeletonComp from "../Components/Organisms/Skeleton";

const NewSuccessPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [IsPending, setIsPending] = React.useState(false);
  const [IsLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true)
    const pending = searchParams.get("pending");
    setIsPending(JSON.parse(pending));
    setIsLoading(false)
  }, []);

  if (IsLoading) {
    return <SkeletonComp />
  }

  if (!IsPending) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center position-absolute w-100 h-100 text-center">
        <img src={`${window.location.origin}/success.png`} alt="" />
        <span className="d-block fw-600 fw-bold my-2 font-inter text-payment-status">
          {t("succeed")}
        </span>
        <span className="d-block font-inter fw-400">
          {t("succeedDescription")}
        </span>
      </div>
    );
  } else {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center vh-100 text-center">
        <img
          src={`${window.location.origin}/kanggo-logo.png`}
          style={{ width: 156, height: 50, top: 10 }}
          alt=""
          className="position-absolute"
        />
        <img
          src={`${window.location.origin}/payment-pending.gif`}
          style={{ height: 390 }}
          alt=""
        />

        <div className="px-5">
          <p className="font-averta-black font-size-22 fw-400">
            Pembayaran Kamu Lagi Diverifikasi
          </p>

          <p className="font-averta font-size-14 fw-400">
            Mohon tetap di halaman ini dan pastikan signal stabil ya
          </p>
        </div>
      </div>
    );
  }
};

export default NewSuccessPage;
