import moment from "moment";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CopyIcon from "../Assets/ion_copy.svg";
import FooterFixed from "../Components/Molecules/FooterFixed";
import AmountDetail from "../Components/Organisms/AmountDetail";
import Card from "../Components/Organisms/Card";
import Container from "../Components/Organisms/Container";
import Countdown from "../Components/Organisms/Countdown";
// import Header from "../Components/Organisms/Header";
import ModalComp from "../Components/Organisms/Modal";
import PaymentProcedureSection from "../Components/Organisms/PaymentProcedureSection";
import { setIsChangePayment, setPaymentMethod } from "../Redux/Actions/payment";
import { alert } from "../Utils/Alert";
import { getFee } from "../Utils/GetFee";
import { pay } from "../Utils/PaySimulation";
import SkeletonComp from "../Components/Organisms/Skeleton";
import ListPaymentCategory from "./ListPaymentCategory";

const PaymentCheckout = ({ socket }) => {
  const [ShowModal, setShowModal] = React.useState(false);
  const [IsLoading, setIsLoading] = React.useState(false);
  const invoice = useSelector((state) => state.invoice?.transaction);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  const location = useLocation();

  const fetch = () => socket.emit("check-transaction", invoice?.external_id);

  React.useEffect(() => {
    socket.on("payment-success", (data) => {
      if (data.external_id === invoice?.external_id) {
        window.location.href = data?.redirect_url;
      }
    });

  }, [socket]);

  if (!location?.state) {
    return <ListPaymentCategory />;
  }

  const { state } = location;
  const { title, method, is_count_amount = true } = state;

  if (IsLoading) {
    return (
      <Container>
        <SkeletonComp />
      </Container>
    );
  } else {
    return (
      <>
        <Container>
          {/* <Header
            showCloseBtn={false}
            title={title}
            closeAction={() => navigate(-1)}
          /> */}
          <div className="p-3 mb-5">
            <ModalComp show={ShowModal} onHide={() => setShowModal(!ShowModal)}>
              <div className="d-flex justify-content-center">
                <img
                  src={invoice?.checkout_url}
                  alt="img"
                  style={{ width: 300, cursor: "pointer", brightness: 100 }}
                  className="m-auto"
                  // onClick={() => setShowModal(true)}
                />
              </div>
            </ModalComp>
            {!invoice?.payment_id && invoice?.is_set_expired && (
              <Countdown date={invoice?.expired_at} callback={fetch} />
            )}
            <Card className="content p-3 bg-linear">
              <div className="d-flex flex-row align-items-center">
                <img
                  src={`${window.location.origin}/verified-poly.png`}
                  style={{ width: 30, height: 30 }}
                  alt=""
                />
                <span className="w-100 font-averta ps-3">
                  <Trans
                    i18nKey={"alert-payment"}
                    components={[<span className="font-averta-bold" />]}
                  />
                </span>
              </div>
            </Card>
            <div className="gap"></div>
            <Card>
              <AmountDetail
                showAmount={true}
                amount={
                  is_count_amount
                    ? invoice?.original_amount +
                      getFee({ ...method, amount: invoice?.original_amount })
                    : invoice?.amount
                }
                fee={getFee({ ...method, amount: invoice?.original_amount })}
                textAmount={t("amount")}
                invoice={invoice?.external_id}
              />
            </Card>
            <div className="gap"></div>
            {invoice?.is_closed_va === 1 && (
              <div className="payment-expired bg-maroon">
                <div className="text-center py-2">
                  <div className="h-100 w-100 m-auto">
                    <span className="font-averta fs-normal fw-400">
                      {t("alertExpired")}
                      <span className="font-averta-bold">
                        {moment(invoice?.expired_at).format(
                          "DD MMMM YYYY HH:mm:ss [WIB]"
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}
            {invoice?.payment_code === "KANGGQRIS01" && (
              <>
                <Card>
                  <div className="d-flex flex-column justify-content-center text-center">
                    <img
                      src={invoice?.checkout_url}
                      alt="img"
                      style={{ width: 250, cursor: "pointer" }}
                      className="m-auto"
                      onClick={() => setShowModal(true)}
                    />

                    <div className="gap"></div>
                    <p className="font-averta">Klik Untuk Memperbesar</p>
                    <div className="gap"></div>
                  </div>
                </Card>
              </>
            )}
            {invoice?.category_id === 1 && (
              <Card>
                <div className="d-flex align-items-center justify-content-between">
                  {/* <span className="font-averta-bold text-kanggo-black font-size-14">{invoice?.name}</span> */}
                  <span className="font-averta-bold text-kanggo-black font-size-16">
                    {title}
                  </span>
                  <img
                    src={method?.image_url}
                    className="logo-payment"
                    alt=""
                  />
                </div>
                <div className="border-bottom my-3"></div>
                <div className="d-flex flex-rows justify-content-between">
                  <span className="font-averta text-kanggo-black font-size-14">
                    {t("name")}
                  </span>
                  <div className="d-flex">
                    <span className="font-averta-bold text-kanggo-black font-size-14">
                      {invoice?.name}
                    </span>
                  </div>
                </div>
                <div className="border-bottom my-3"></div>
                <div className="d-flex flex-rows justify-content-between">
                  <span className="font-averta text-kanggo-black font-size-14">
                    {t("virtualAccountText")}
                  </span>
                  <div className="d-flex">
                    <span className="font-averta-bold text-kanggo-black font-size-14">
                      {invoice?.invoice_number}
                    </span>
                    <CopyToClipboard
                      text={invoice?.invoice_number}
                      onCopy={() => alert("success", t("copied"))}
                    >
                      <img
                        src={CopyIcon}
                        className="ms-2"
                        style={{ cursor: "pointer", width: 20 }}
                        alt="copy-icon"
                      />
                    </CopyToClipboard>
                  </div>
                </div>
              </Card>
            )}

            <div className="gap"></div>
            {method?.flows.length > 0 && (
              <Card>
                <PaymentProcedureSection
                  method={method}
                  showImage={false}
                  t={t}
                />
              </Card>
            )}
            <div className="gap"></div>
          </div>
        </Container>
        <FooterFixed>
          <div className="d-flex flex-column justify-content-between align-items-center w-100">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center">
                <img
                  src={`${window.location.origin}/Wallet.png`}
                  style={{ width: 25, height: 25, marginRight: 10 }}
                  alt=""
                />
                <div className="d-flex flex-column align-item-center">
                  <span className="footer-text-payment-method">
                    Metode Pembayaran
                  </span>
                  <span className="footer-payment-method font-averta-bold">
                    {invoice?.payment_name}{" "}
                    <img
                      src={`${window.location.origin}/checked.png`}
                      style={{ width: 15 }}
                      alt=""
                    />
                  </span>
                </div>
              </div>
              <button
                className="btn btn-sm btn-outline-danger radius-50"
                onClick={() => {
                  // sessionStorage.setItem("is_change", 1);
                  // sessionStorage.setItem("payment_method", invoice?.payment_id);

                  dispatch(setPaymentMethod(invoice?.payment_id));
                  dispatch(setIsChangePayment(1));

                  setTimeout(() => {
                    navigate(`/?q=${invoice?.external_id}`);
                  }, 100);
                }}
              >
                {" "}
                {t("changePayment")}
              </button>
            </div>

            <div className="gap"></div>
            <div className="d-flex justify-content-between align-items-center w-100">
              {/* <button
                className="btn btn-outline-danger radius-50 w-100 font-averta"
                onClick={() => (window.location.href = "https://kanggo.id")}
              >
                {t("backToApp")}
              </button> */}
              {((process.env.REACT_APP_ENV !== "development" &&
                invoice.is_paid === 0 &&
                invoice.checkout_url &&
                [2, 5, 13].includes(invoice?.category_id)) ||
                (process.env.REACT_APP_ENV === "development" &&
                  invoice.payment_code !== "ID_OVO" &&
                  // invoice.payment_provider === "SPRINT" &&
                  invoice.is_closed_va === 1)) && (
                <>
                  <div className="px-1"></div>
                  <button
                    className="btn btn-danger btn-gradient-primary radius-50 w-100 font-averta"
                    onClick={() => {
                      if (process.env.REACT_APP_ENV === "development") {
                        if (invoice?.category_id === 1) {
                          if (invoice?.payment_provider === "XENDIT") {
                            pay(invoice).then(() => setIsLoading(true));
                          } else {
                            alert(
                              "warn",
                              "please using simulator to simulate payment"
                            );
                          }
                        } else if (invoice?.checkout_url) {
                          window.location.href = invoice.checkout_url;
                        }
                      } else {
                        if (invoice.checkout_url) {
                          window.location.href = invoice.checkout_url;
                        }
                      }
                    }}
                  >
                    {t("payContinue")}
                  </button>
                </>
              )}
            </div>
          </div>
        </FooterFixed>
      </>
    );
  }
};

export default PaymentCheckout;
