import axios from "axios";
import { alert } from "./Alert";

axios.defaults.headers.common["Authorization"] = process.env.REACT_APP_JWT;

// const storageData = JSON.parse(localStorage.getItem("data"));

export const getPaymentCategories = async (source_id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/switching/api/v1/payment/category`,
      { params: { source_id } }
    );
    return data;
  } catch (error) {
    alert("error", "server error");
    // console.log(storageData)
    // window.location.href =
    //   storageData.failed_redirect_url || window.location.origin + "/failed";
    console.log(error);
  }
};

export const getPayments = async (category_id, source_id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/switching/api/v1/payment/list`,
      { params: { category_id, source_id } }
    );
    return data;
  } catch (error) {
    alert("error", "server error");
    // console.log(storageData)
    // window.location.href =
    //   storageData.failed_redirect_url || window.location.origin + "/failed";
    console.log(error);
  }
};

export const getPaymentProcedure = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/switching/api/v1/payment/pay_flow`,
      { params: { payment_gateway_id: id } }
    );
    return data;
  } catch (error) {
    alert("error", "server error");
    // console.log(storageData)
    // window.location.href =
    //   storageData.failed_redirect_url || window.location.origin + "/failed";
    console.log(error);
  }
};

export const connectToDana = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/switching/api/v1/payment/dana/store_token`,
      payload
    );
    return data;
  } catch (error) {
    alert("error", "server error");
    // console.log(storageData)
    // window.location.href =
    //   storageData.failed_redirect_url || window.location.origin + "/failed";
    console.log(error);
  }
};

export const getTransaction = async (external_id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/switching/api/v1/pay/status`,
      { params: { external_id } }
    );

    return data;
  } catch (error) {
    alert("error", "server error");
    // console.log(storageData)
    // window.location.href =
    //   storageData.failed_redirect_url || window.location.origin + "/failed";
    console.log(error);
  }
};

export const payBill = async (url, data) => {
  try {
    const invoice = await axios.post(url, data);
    return invoice.data.data;
  } catch (error) {
    alert(
      "error",
      "terjadi kesalahan, silakan coba lagi atau ganti metode pembayaran"
    );
    // console.log(storageData)
    // window.location.href =
    //   storageData.failed_redirect_url || window.location.origin + "/failed";
    console.log(error);
  }
};

export const paySimulate = async (url, data, redirect) => {
  try {
    const invoice = await axios.post(url, data);
    // window.location.href = redirect;
    return invoice.data.data;
  } catch (error) {
    alert(
      "error",
      "something wrong, please try again or change payment method !!"
    );
    // alert('please try again')
    // console.log(storageData)
    // window.location.href =
    //   storageData.failed_redirect_url || window.location.origin + "/failed";
    // console.log(error);
  }
};

export const instance = axios;
