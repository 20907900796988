import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ICActSuccess from "../Assets/ICDanaActivationSuccess.svg";
import SkeletonComp from "../Components/Organisms/Skeleton";
import { connectToDana } from "../Utils/Api";

const DanaActivationSuccess = () => {
  const [IsLoading, setIsLoading] = React.useState(false);
  const [searchParams] = useSearchParams();

  const connectDana = async () => {
    setIsLoading(true);
    const user_id = searchParams.get("state");
    const auth_code = searchParams.get("auth_code");

    const payload = { user_id, auth_code };

    await connectToDana(payload);
    setIsLoading(false);
  };

  useEffect(() => {
    connectDana();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container col col-lg-5 col-sm-12 col-md-12 px-3">
      {IsLoading && <SkeletonComp />}
      {!IsLoading && (
        <div className="text-center pt-5">
          <img src={ICActSuccess} className="" alt="" srcSet="" />
          <span className="d-block dana-activation-title">
            Aktivasi DANA Berhasil!
          </span>
          <span className="d-block dana-activation-desc pt-1">
            DANA-mu sudah aktif dan bisa dipakai untuk bertransaksi.
          </span>
          <div className="d-grid gap-2 pt-3">
            <button className="btn dana-activation-btn" onClick={() => window.location.href = 'https://kanggo.id'}>
              Lanjut ke Beranda
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DanaActivationSuccess;
