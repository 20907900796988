/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import Countdown from "react-countdown";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import FooterFixed from "../Components/Molecules/FooterFixed";
import List from "../Components/Molecules/List";
import Card from "../Components/Organisms/Card";
import Container from "../Components/Organisms/Container";
import Countdown from "../Components/Organisms/Countdown";
// import Header from "../Components/Organisms/Header";
import SkeletonComp from "../Components/Organisms/Skeleton";
import { getInvoiceData } from "../Redux/Actions/invoice";
import { setIsChangePayment } from "../Redux/Actions/payment";
import { BeautifyUrl } from "../Utils/BeautifyUrl";
import { getFee } from "../Utils/GetFee";

const ListPaymentCategory = ({ socket }) => {
  const { invoice, payment } = useSelector((state) => state);
  const [Categories, setCategories] = useState(invoice?.data);
  const [TransactionData, setTransactionData] = useState(
    invoice?.transaction || null
  );

  const [searchParams] = useSearchParams();
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetch = () =>
    socket.emit("check-transaction", TransactionData?.external_id);

  useEffect(() => {
    const external_id = searchParams.get("q");
    if (!external_id) return navigate("/expired");
    dispatch(getInvoiceData(external_id));
  }, []);

  useEffect(() => {
    setCategories(invoice?.data || []);
    setTransactionData(invoice?.transaction || {});
  }, [invoice]);

  if (
    TransactionData?.is_paid === 0 &&
    TransactionData?.payment_id &&
    !payment?.is_change
  ) {
    navigate(`/checkout`, {
      state: {
        method: Categories.find(
          (e) => e.id === TransactionData?.category_id
        )?.methods?.find((e) => e.id === TransactionData?.payment_id),
        title: TransactionData.payment_name,
      },
    });
  }

  return (
    <Container>
      {/* <Header
        title={t("selectPayment")}
        showCloseBtn={false}
        closeAction={() => {}}
      /> */}
      <div className="p-3">
        {TransactionData &&
          !TransactionData?.payment_id &&
          TransactionData?.is_set_expired && (
            <Countdown date={TransactionData?.expired_at} callback={fetch} />
          )}
        {/* {IsLoading && <SkeletonComp />} */}
        {Categories.length < 1 && <SkeletonComp />}
        {TransactionData && (
          <Card>
            <div className={`${+payment?.is_change === 1 && "mb-4"}`}>
              {Categories.map((e, i) => (
                <List
                  index={i}
                  dataLength={Categories?.length}
                  key={i}
                  title={e.category}
                  description={e.description}
                  img={e.image_url}
                  fee={
                    e.id === 3
                      ? getFee({
                          amount: TransactionData?.original_amount,
                          ...e.methods[0],
                        })
                      : 0
                  }
                  action={() => {
                    const paymentUrl =
                      e.methods.length > 1
                        ? `/payments/${BeautifyUrl(e.category)}`
                        : `/payments/${e.category}/${BeautifyUrl(
                            e.methods[0]?.method
                          )}`;

                    const paymentState =
                      e.methods.length > 1
                        ? { title: e.category, methods: e.methods }
                        : { title: e.methods[0]?.method, method: e.methods[0] };

                    navigate(paymentUrl, { state: paymentState });
                  }}
                />
              ))}
            </div>
            {+payment?.is_change === 1 && (
              <FooterFixed>
                <div className="d-flex flex-row justify-content-between align-items-center m-auto w-100">
                  <div className="d-flex flex-row align-items-center">
                    <img
                      className="img-fluid"
                      src={`${window.location.origin}/Wallet.png`}
                      style={{ width: 25, height: 25, marginRight: 5 }}
                      alt=""
                    />
                    <Countdown
                      date={TransactionData?.expired_at}
                      callback={fetch}
                      type="footer"
                    />
                    {/* <div className="d-flex flex-column">
                      <span className="font-averta-bold">
                        {t("expiredReminder")}
                      </span>
                      <span style={{ marginTop: -3 }} className="font-averta">
                        {TransactionData?.expired_at?.split(" ")[0]} |{" "}
                        <span className="text-hours">
                          {TransactionData?.expired_at?.split(" ")[1]}
                        </span>
                      </span>
                    </div> */}
                  </div>
                  <button
                    className="btn btn-danger radius-50 btn-gradient-primary font-averta"
                    onClick={() => {
                      dispatch(setIsChangePayment(0));

                      setTimeout(() => {
                        navigate(`/checkout`, {
                          state: {
                            method: Categories.find(
                              (e) => e.id === TransactionData?.category_id
                            )?.methods?.find(
                              (e) => (e.id = TransactionData?.payment_id)
                            ),
                            title: TransactionData.payment_name,
                          },
                        });
                      }, 100);
                    }}
                  >
                    Lihat
                  </button>
                </div>
              </FooterFixed>
            )}
          </Card>
        )}
      </div>
    </Container>
  );
};

export default ListPaymentCategory;
