export const getCardType = (cardNumber) => {
  // Define regular expressions for different card types
  const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const mastercardRegex = /^5[1-5][0-9]{14}$/;
  const amexRegex = /^3[47][0-9]{13}$/;
  const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
  const jcbRegex = /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/;

  const cleanCardNumber = cardNumber.replace(/\s/g, '')

  if (visaRegex.test(cleanCardNumber)) {
    return 'visa';
  } else if (mastercardRegex.test(cleanCardNumber)) {
    return 'mastercard';
  } else if (amexRegex.test(cleanCardNumber)) {
    return '';
  } else if (jcbRegex.test(cleanCardNumber)) {
    return 'jcb'
  } else if (discoverRegex.test(cleanCardNumber)) {
    return '';
  } else {
    return '';
  }
}