import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonComp = () => {
  return (
    <div className="p-2">
      <SkeletonTheme baseColor="#FFFF" highlightColor="#eaeaea">
        <p>
          <Skeleton height={90} count={6} />
        </p>
      </SkeletonTheme>
    </div>
  );
};

export default SkeletonComp;
