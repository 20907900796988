import { instance } from "../../Utils/Api";

export const getInvoiceData = (external_id) => {
  return (dispatch) => {
    instance
      .get(
        `${process.env.REACT_APP_API_URL}/switching/api/v2/invoice/${external_id}`
        // `${process.env.REACT_APP_API_URL}/switching/api/v1/payment/invoice?external_id=${external_id}`
      )
      .then((res) => {
        dispatch({ type: "GET_INVOICE", payload: res.data.data });
      })
      .catch((err) => console.log(err));
  };
};
