import React from "react";
import { useSearchParams } from "react-router-dom";

const Pay = () => {
  const [searchParams] = useSearchParams();
  const [Data, setData] = React.useState(null);

  React.useEffect(() => {
    const q = searchParams.get("q");
    const data = JSON.parse(window.atob(q));
    setTimeout(() => {
      setData(data);
    }, 1200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = () => {
    const btn = document.getElementById("continue");
    btn.click();
  };

  return (
    <>
      {!Data && (
        <div className="d-flex flex-column justify-content-center align-items-center position-absolute w-100 h-100 text-center">
          <img src={`${window.location.origin}/load.gif`} alt="" />
        </div>
      )}
      {Data && (
        <>
          <form
            method="post"
            id="form"
            action={Data?.redirect_url}
            onSubmit={setTimeout(() => {
              submit();
            }, 1)}
          >
            <input
              type="hidden"
              name="keysTrxEcomm"
              value={Data?.redirect_data.keysTrxEcomm}
            />
            <button id="continue">continue</button>
          </form>
        </>
      )}
    </>
  );
};

export default Pay;
