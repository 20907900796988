import React from "react";
import { useTranslation } from "react-i18next";

const ExpiredPage = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column justify-content-center align-items-center position-absolute w-100 h-100 text-center">
      <img src={`${window.location.origin}/expired.png`} alt="" />
      <span className="d-block fw-600 fw-bold my-2 font-inter text-payment-status">
        {t('expired')}
      </span>
      <span className="d-block font-inter fw-400">
        {t('expiredDescription')}
      </span>

      <button
        className="btn btn-danger mt-3 px-5 fw-600 font-inter btn-expired-action"
        style={{ fontSize: 17 }}
        onClick={() => window.location.href = 'https://kanggo.id'}
      >
        {t('expiredAction')}
      </button>
    </div>
  );
};

export default ExpiredPage;
