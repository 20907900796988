import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { io } from "socket.io-client";
import "./i18n";
import DanaActivationSuccess from "./Pages/DanaActivationSuccess";
import ExpiredPage from "./Pages/ExpiredPage";
import FailedPage from "./Pages/FailedPage";
import Pay from "./Pages/Pay";
import PaymentCheckout from "./Pages/PaymentCheckout";
import PaymentDetail from "./Pages/PaymentDetail";
import PendingPage from "./Pages/PendingPage";
import SuccessPage from "./Pages/SuccessPage";
import { getInvoiceData } from "./Redux/Actions/invoice";
import ListPaymentCatSkeleton from "./Skeletons/ListPaymentCatSkeleton";
import NewSuccessPage from "./Pages/NewSuccessPage";
const ListPayment = React.lazy(() => import("./Pages/ListPayment"));
const ListPaymentCategory = React.lazy(() =>
  import("./Pages/ListPaymentCategory")
);

const socket = io(process.env.REACT_APP_API_URL, {
  withCredentials: true,
  // transports: ["websocket"],
});

const routes = [
  {
    path: "/dana/activation/success",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <DanaActivationSuccess />
      </React.Suspense>
    ),
  },
  {
    path: "/",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <ListPaymentCategory socket={socket} />
      </React.Suspense>
    ),
  },
  {
    path: "/pay",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <Pay />
      </React.Suspense>
    ),
  },
  {
    path: "/success",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <SuccessPage />
      </React.Suspense>
    ),
  },
  {
    path: "/v2/success",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <NewSuccessPage />
      </React.Suspense>
    ),
  },
  {
    path: "/failed",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <FailedPage />
      </React.Suspense>
    ),
  },
  {
    path: "/expired",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <ExpiredPage />
      </React.Suspense>
    ),
  },
  {
    path: "/pending",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <PendingPage />
      </React.Suspense>
    ),
  },
  {
    path: "/payments/:category",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <ListPayment socket={socket} />
      </React.Suspense>
    ),
  },
  {
    path: "/payments/:category/:payment",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <PaymentDetail socket={socket} />
      </React.Suspense>
    ),
  },
  {
    path: "/checkout",
    element: (
      <React.Suspense fallback={<ListPaymentCatSkeleton />}>
        <PaymentCheckout socket={socket} />
      </React.Suspense>
    ),
  },
];

const Apps = () => {
  const data = useSelector((state) => state.invoice.transaction);
  const dispatch = useDispatch();

  // client-side
  socket.on("connect", () => {
    console.log("socket connect");
  });

  socket.on("check-transaction-result", () => {
    // const { is_paid, external_id: external_id_from_payload } = payload;
    const { external_id } = data;
    dispatch(getInvoiceData(external_id))

    setTimeout(() => {
      window.location.href = '/'
    }, 2000);

    // if (external_id_from_payload === external_id && [2].includes(is_paid)) {
    //   window.location.href = "/expired";
    // }
  });

  localStorage.setItem("lang", "id");

  // window.socket = socket;

  // if (data?.is_paid === 0 && data?.payment_id) {
  //   console.log(data)
  //   console.log(methods)
  //   // window.location.href = "https://kanggo.id";
  // }

  if (data?.is_paid === 1) {
    window.location.href = "/success";
  }

  if (data?.is_paid === 2) {
    window.location.href = "/expired";
  }

  return (
    <Suspense fallback={null}>
      <Router>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Routes>
          {routes.map((e, i) => (
            <Route key={i} {...e} />
          ))}
        </Routes>
      </Router>
    </Suspense>
  );
};

export default Apps;
