import React from "react";

const Frame = ({src = 'https://kanggo.id'}) => {
  return (
    <div
      className="w-100 h-100"
      style={{
        display: "flex",
        flex: 1,
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0,0,0,0.5)',
        // opacity: "0.3",
        zIndex: 5000,
        overflow: 'hidden'
      }}
    >
      <iframe
        src={src}
        frameBorder="0"
        className="bg-light"
        title="3DS"
        width={550}
        height={450}
      ></iframe>
    </div>
  );
};

export default Frame;
