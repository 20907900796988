export const BeautifyUrl = (url) =>
  url
    .toLowerCase()
    .split(" ")
    .join("-")
    .split("/")
    .join("-")
    .split("(")
    .join("")
    .split(")")
    .join("");
