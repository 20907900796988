const initialState = {
  payment_method: null,
  is_change: 0,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PAYMENT_METHOD":
      return { ...state, payment_method: payload };

    case "SET_IS_CHANGE_PAYMENT_METHOD":
      return { ...state, is_change: payload };

    default:
      return state;
  }
};

export default reducer;
