import { toast } from "react-toastify";
export const alert = (type = "warn", message = "Please Try Again!!") =>
  toast[type](message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
