import React from "react";
import ArrowRight from "../Atoms/ArrowRight";

const List = ({
  title,
  description,
  fee = 0,
  term_condition = null,
  handleTermConditionModal = () => {},
  maxAmount = 0,
  minAmount = 0,
  showMaxMinAmount = false,
  img = "https://kanggo.id/wp-content/uploads/2022/02/logo-kanggo-287-x-81.webp",
  action = () => {
    alert("oke");
  },
  dataLength = 1,
  index = 0,
}) => {
  const borderBottom = index + 1 !== dataLength ? "border-bottom" : "";

  return (
    <div
      className={`d-flex align-items-center justify-content-center m-0 list ${borderBottom} w-100`}
      onClick={() => (term_condition ? {} : action)}
      style={{ ...(!term_condition && { cursor: "pointer" }) }}
    >
      <div className="pe-3" style={{ cursor: "pointer" }} onClick={action}>
        <img src={img} className="logo-payment" alt="" srcSet="" />
      </div>
      <div className="d-flex flex-column w-75">
        <span
          onClick={action}
          style={{ cursor: "pointer" }}
          className="category-title"
        >
          {title}
        </span>
        <span
          onClick={action}
          style={{ cursor: "pointer" }}
          className="category-description"
        >
          {description}
        </span>
        <span className="d-block category-description text-maroon">
          {fee ? `Biaya Admin Rp. ${new Intl.NumberFormat("id").format(fee)}` : ""}
        </span>
        {term_condition && (
          <span
            className="font-averta text-maroon text-decoration-underline"
            onClick={handleTermConditionModal}
            style={{ cursor: "pointer", fontSize: 12 }}
          >
            Syarat dan Ketentuan
          </span>
        )}
      </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={action}
        className="m-auto text-end"
      >
        <ArrowRight />
      </div>
    </div>
  );
};

export default List;
