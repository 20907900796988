import React from "react";
import HowToPay from "./HowToPay";

const PaymentProcedureSection = ({ method, t, showImage = true }) => {
  const [Flows, setFlows] = React.useState(0);
  return (
    <>
      <div className="content">
        <div className="row m-0 py-3 border-bottom border-list">
          <div className="col ps-0 mx-0 col-6 m-auto text-start">
            <span className="font-averta-bold text-kanggo-black font-size-16">
              {t("payTutorial")}
            </span>
          </div>
          {showImage && (
            <div className="col ps-0 mx-0 col-6 m-auto text-end">
              <img
                src={method.image_url}
                className="logo-payment"
                alt=""
                srcSet=""
              />
            </div>
          )}
        </div>
        <div className="row m-0 py-3">
          {method.flows.map((e, i) => (
            <div
              key={i}
              className="col ps-0 mx-0 col-4 m-auto text-start"
              onClick={() => setFlows(i)}
            >
              <div className="d-grid gap-2" role="button">
                <div
                  className={`btn btn-light ${
                    Flows === i
                      ? "border-btn-tab font-averta-bold text-kanggo-black font-size-16"
                      : "font-averta text-kanggo-black font-size-16"
                  }`}
                >
                  {e.method}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <HowToPay data={method.flows[Flows]?.flows || []} />
    </>
  );
};

export default PaymentProcedureSection;
